.card {
    margin-bottom: 10px;
    border-radius: 25px;
    background-position: center;
    background-size: cover;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map {
    margin-right: 18px;

    display: flex;
    flex-direction: column;
    position: relative;
}

.mapImage {
    border: 2px black solid;
    box-shadow: 0 0 10px black;
    --mapImageSize: 106px;
    width: var(--mapImageSize);
    height: var(--mapImageSize);
}

:global(.bp5-dark) .mapImage {
    border-color: white;
}

.rightCell {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.name {
    font-size: large;
    display: flex;
    flex-direction: row;
}

.isMyTurn {
    border: 5px green solid;
}

.currentSeatObjectIsOverlyIdle {
    border: 5px red solid;
}

:global(.bp5-dark) .isMyTurn {
    border: 5px #40e040 solid !important;
}

.didWin {
    border: 5px gold solid;
}

.info {
    margin-bottom: 20px;
}

.crown {
    width: 24px;
    filter: drop-shadow(0px 0px 5px black);
    margin-right: 5px;
}

.avatars {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mapName {
    font-weight: bold;
    margin-bottom: 5px;
}


.modeIcon {
    max-width: 24px;
    max-height: 24px;
    fill: black;
    margin-right: 5px;
}

:global(.bp5-dark) .modeIcon {
    fill: white;
}

.link,
.link:hover {
    text-decoration: none;
    color: var(--text-color) !important;
}
